import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import NavBar from '../../Common/navbar';
import APP_BASE_URL from '../../base_url';

const Intro = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        const audio = new Audio('/intro.wav');
        audio.loop = true; // Loop the audio
        audio.play();
        return () => {
            audio.pause(); // Pause the audio when component unmounts
        };
    }, []);
    return (
        <Box sx={{p:0}}>
            <NavBar />
            <Box sx={{m:5}}>
                <Typography variant="h2" component="h2" align="center" gutterBottom>
                    Introduction
                </Typography>
            </Box>
            <Box sx={{ml:20, mr:20, mb:5}}>
                <Typography variabt="body1">
                    The game you are about to play is part of an activity designed to give participants a better understanding of the stages in people’s career advancement and the various experiences they have along the way. You will choose one of two versions, one that shows university faculty experience, the other focuses on the experience of people working as consultants
                    <br/>
                    The object of the game is to advance as far as you can and to be the first person to become a Partner (Consulting version) or Distinguished Professor (Academic version).
                    <br/>
                    The first thing to do is to break up into two teams [as even as possible]… On one side of the table are orange cards, and on the other side are blue cards.  Those players closet to the orange cards are on the Orange Team, and those players closet to the blue cards are on the Blue Team.
                    <br/>
                    Everyone should now choose a game piece that they will use throughout the game.  Players on the Orange Team should choose a piece from the orange[-bottomed] pieces.  Players on the Blue Team should choose a piece from the blue[-bottomed] pieces.  Once you choose a piece, place it on the square with Internship Bachelor’s.
                    <br/>
                    Gameplay is simple.  Players take turns picking up a card, read it out loud, and then follow the directions on the card.  A Blue Team member will go first, then a Orange Team member, then back to the Blue Team, and so on.  Play continues to alternate between teams and players throughout the game.  After you read your card, hold on to it so that you can refer back to it after the game in our discussion.
                    <br/>
                    Each card has two pieces of information: (1) how many spaces a player should move forward, and (2) how many credit chips a player earns for that round.  For example, a card may describe what happens in a committee meeting and then instruct the player to move forward one space and pick up two chips.  The spaces you move forward represent the speed at which you advance in your career.  The credit chips represent your Resume/CV.
                    <br/>
                    You will notice that there are three levels on the game board in each version. For the Consultant version they are: Analyst/Associate, Consultant, and Project Leader. For the Academic version they are Assistant Professor, Associate Professor, Full Professor. There are separate game cards for each level, so you should always take a card from the set that corresponds to your current level in the game.  Also, for each level you may see different color credit chips.  All credit chips have the same value, but, when possible, take credit chips from the color for the level you are on.
                    <br/>
                    If you earn two or more chips on a turn, you are doing very well; if you earn one or not chips on a turn, you are not doing well and may need to use another route to earning credit chips.
                    <br/>
                    If you look at the board, you will see that to advance from one level to the next, you will need to be approved. To be order to be approved, you simply need to have enough credit chips. For example to become a Consultant, you need six credit chips.  To become a Project Leader, you need a total of sixteen credit chips (that is, 10 new credit chips since your promotion).  To win the game you need 30 credit chips (that is, 14 new credit chips since your last promotion).  Players keep their chips after being promoted to a new level.
                    <br/>
                    It is important for you to manage your career by making sure you have the correct number of credit chips as you advance.  If you do not have the minimum number of required chips when applying for the first promotion, otherwise, you must go back to the beginning of the game and start over!  If you do not have enough chips at the other two promotion spaces, you must go back one space and lose a turn.
                    <br/>
                    If you are falling behind, you can increase the number of chips you have by using the gray spaces to the side of the board.  You can only go to one of these spaces when an arrow points from the main board towards the space. It takes two turns to use this option. On your first turn, you do not draw a card but instead move your game piece to this space.  You earn the number of credit chips indicated, but do not advance forward for the turn.  In the next round when it is your turn again, draw a card and move back to the game board where the arrow points.  Thus, while you will earn extra chips by using these spaces, your advancement forward will be slowed as it will take two turns to move just one space forward, so it is not a good idea to use this option unless you really need it.
                    <br/>
                    One last thing. Even though the rules of the game are really simple, sometimes questions will arise. To make things easier and keep the game moving, if there are any disputes, a majority of the Blue Team will make the final decision. 
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <Button sx={{ width: '100px', height: '25px' }} variant="contained" onClick={() => navigate("/")}>Go Back</Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Intro;

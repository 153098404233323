import React, {useState, useEffect} from 'react';
import io from 'socket.io-client'
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button, Grid, Avatar, Stack, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { teal, grey } from '@mui/material/colors';
import NavBar from '../../Common/navbar';
import { useNavigate } from "react-router-dom";
import { bgcolor, style } from '@mui/system';
import RouteUrls from "../../Routes/route-urls";
import APP_BASE_URL from '../../base_url'
import AdminTable from './AdminTable';
import axios from "axios"

const AdminView= () => {

    const navigate = useNavigate();
    const already_loggedin = localStorage.getItem("adminLoggedin") !== null
    const [loggedin, setLoggedin] = useState(already_loggedin);
    const [pwderror, setPwderror] = useState(false);
    const [helperText, setHelperText] = useState("");
    const handleNameChange = (name) => {
        localStorage.setItem("password", name.target.value)
    };
    const handlSubmit = () => {
        axios.get(
            APP_BASE_URL + "/passwordCheck?password="+localStorage.getItem("password")
        ).then((response) => {
            if(response.data == "success"){
                setLoggedin(true);
                localStorage.setItem("adminLoggedin", true)
            }
            else{
                setPwderror(true)
                setHelperText(response.data)
            }
        })
    };
    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            handlSubmit();
        }
    };
    const password_block = (
        <Box sx={{mt:10}}>
            <Grid container spacing={5} justifyContent="center">
                <Grid item xs={8} align="center">
                    <TextField 
                        error={pwderror} 
                        id="password" 
                        label="Password" 
                        type="password" 
                        variant="standard" 
                        helperText={helperText}
                        onKeyPress={handleKeyDown} 
                        onChange={handleNameChange}
                    />
                </Grid>
                <Grid item xs={8} align="center">
                    <Button onClick={handlSubmit}>Confirm</Button>
                </Grid>
            </Grid>
        </Box>
    );
    return (
        <Box sx={{p:0}}>
            <NavBar />
            {!loggedin && password_block}
            {loggedin && <AdminTable></AdminTable>}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <Button sx={{ width: '100px', height: '25px' }} variant="contained" onClick={() => navigate('/')}>Go Back</Button>
            </Box>
        </Box>
    );
};

export default AdminView;
import React, {useState, useEffect} from 'react';
import io from 'socket.io-client'
import { Button, Grid, Select,FormControl, Tab, Tabs, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import RouteUrls from "../../Routes/route-urls";
import APP_BASE_URL from '../../base_url'
import { MenuItem } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {emptyRoom} from '../NewGame'

const GameTable = (props) => {
    const players = props.data.player_array;
    const uuid = props.data.uuid;
    const game_running = props.data.game_running.toString();
    const game_finished = props.data.game_ended.toString();
    const socket = props.socket;
    
    // const room_remover =  () => {
    //     socket.emit("delete_game", {game_id: uuid});
    //     emptyRoom(uuid);
    // }

    const player_remover =  (name) => {
        return () => socket.emit("delete_player", {game_id: uuid, username: name});
    } 

    return (
        <>
            <TableContainer sx={{ minWidth: 500, maxWidth: 1300, bgcolor: grey[100]}} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>Game Id: {uuid}</TableCell>
                            <TableCell colSpan={3}>Game Started: {game_running}</TableCell>
                            <TableCell colSpan={3}>Game Finished: {game_finished}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Player Name</TableCell>
                            <TableCell align="left">Position</TableCell>
                            <TableCell align="left">Level</TableCell>
                            <TableCell align="left">Chips</TableCell>
                            <TableCell align="left">Team</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {players.map((player) => (
                        <TableRow
                        key={player.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {player.name}
                            </TableCell>
                            <TableCell align="left">{player.boardPosition}</TableCell>
                            <TableCell align="left">{player.position}</TableCell>
                            <TableCell align="left">{player.chips}</TableCell>
                            <TableCell align="left">{player.team.toLowerCase()}</TableCell>
                            <TableCell align="left">
                                <Button sx={{width:150}} onClick={player_remover(player.name)}>Delete User</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                        {/* <TableRow>
                            <TableCell colSpan={3}>
                                <Button onClick={room_teleporter}>Go To Game</Button>
                        <TableRow>
                            <TableCell>
                                <Button sx={{width:100}}>Start Game</Button>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <ProgressLine players={players} uuid={uuid} /> */}
        </>
      );

}

const AdminTable = () => {
    const [socket, setSocket] = useState(null);
    const [currentTab, setCurrentTab] = useState(-1);
    const [gameList, setGameList] = useState([]);
    var games_paused = JSON.parse(localStorage.getItem("gamespaused"));
    const [game_is_paused, setGamePaused] = useState(games_paused);
    const [room, setRoom]= useState('');
    var ended_games = JSON.parse(localStorage.getItem("gamesended"));
    const [game_ended, setGameEnded] = useState(ended_games);
    const navigate = useNavigate();
    useEffect(() => {
        const newSocket = io(APP_BASE_URL);
        setSocket(newSocket);
        return () => newSocket.close();
    }, []);

    const info_handle = (info) => {
        console.log(info);
        setGameList(info);
    }
    const restart_games =  () => {
        socket.emit("restart_games");
        
    }
    const start_games = () => {
        localStorage.setItem("gamesended",false)
        setGameEnded(false);
        socket.emit("startGames");
    };

    const start_game = (event) => {
        localStorage.setItem("gamesended",false)
        setGameEnded(false);
        socket.emit("startGame", {game_id: event.target.value, info: gameList});
        window.location.href = window.location.href;
    };

    const paused_games = () => {
        localStorage.setItem("gamespaused", true)
        setGamePaused(true);
        socket.emit("paused_game");
    };

    const end_games = () => {
        localStorage.setItem("gamesended",true)
        setGameEnded(true);
        socket.emit("end_game");
    };
    const continue_games = () => {
        localStorage.setItem("gamespaused", false)
        setGamePaused(false);
        socket.emit("continue_game");
    };

    const delete_games = () => {
        localStorage.setItem("gamesended",false)
        setGameEnded(false);
        socket.emit("delete_games");
        for(let game in gameList){
            emptyRoom(gameList[game].uuid);
        }
        localStorage.clear();
    };

    const handleRoomStuff = (gameId) => {
        const selected = gameId.target.value;
        if (selected === "JKP") {
            setCurrentTab(1);
        } else if (selected === "JFF") {
            setCurrentTab(0);
        } else if (selected === "ZOOM") {
            setCurrentTab(2);
        }
        else {
            setCurrentTab(-1);
        }
    };

    const room_teleporter = (event) => {
        setRoom(event.target.value);
        localStorage.setItem("username", "admin123");
        localStorage.setItem("game_id", event.target.value);
        navigate("../" + RouteUrls.MAINGAME);
}
    
    useEffect(()=>{
        if(socket !== null){
            const join_data = {
                username: "real_admin"
            };
            socket.emit('user_join', join_data, info_handle);
            socket.on('game_info', info_handle);
        }
    }, [socket])

    const ButtonStyle = {
        backgroundColor: grey[100],
        borderRadius: 4,
        width: 200,
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        margin: 16,
        padding: 8,
    };

    return (
        <Box sx={{mt: 5, mb:5}}>
            <Grid align="center">
                <Button style={ButtonStyle} onClick={start_games}>Start Games</Button>
                {game_is_paused ?
                  (
                    <Button
                      style={ButtonStyle}
                      onClick={continue_games}
                      disabled= {game_ended}
                    >
                      Continue Games
                    </Button>
                  )
                  : (
                    <Button
                      style={ButtonStyle}
                      onClick={paused_games}
                      disabled= {game_ended}
                    >
                      Pause Games
                    </Button>
                  )}
                <Button disabled= {game_ended} style={ButtonStyle} onClick={restart_games}>Restart Games</Button>
                <Button style={ButtonStyle} onClick={delete_games}>Delete Games</Button>
                <Button style={ButtonStyle} onClick={() => navigate('/summary')} >Go to Summary</Button>
                <FormControl  >
                    <InputLabel style={{margin:16}}id="demo-simple-select-label">Go to Game</InputLabel>
                    <Select 
                        labelId="demo-simple-select-label"
                        label="Go To Game"
                        id="demo-simple-select"
                        style={{width:200, margin:16}}
                        onChange={room_teleporter} 
                        value={room}
                    >
                        {gameList.map((game) => {
                            return (
                                <MenuItem value={game.uuid}>{game.uuid}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel style={{margin:16}}id="demo-simple-select-label">Start Game</InputLabel>
                    <Select 
                        labelId="demo-simple-select-label"
                        label="Start Game"
                        id="demo-simple-select"
                        style={{width:200, margin:16}}
                        onChange={start_game} 
                        value={room}
                    >
                        {gameList.map((game) => {
                            if(game.game_running === false){
                                return (
                                    <MenuItem value={game.uuid}>{game.uuid}</MenuItem>
                                );
                            }   
                        })}
                    </Select>
                </FormControl>
                <Button style={ButtonStyle} onClick={end_games}>End All Games</Button>
                <select style={{width:200, margin:16, height: 40}} id="gameID" label= "Game" onChange={handleRoomStuff}>
                    <option value="JFF" selected disabled>Filter by building</option>
                    <option value="ALL">ALL</option>
                    <option value="JKP">JKP</option>
                    <option value="JFF">JFF</option>
                    <option value="ZOOM">ZOOM</option>
                </select>

            </Grid>
            <Grid container spacing={3} justifyContent="center">
                    {gameList.map((game) => {
                        if(currentTab === -1 || game.uuid.includes("JFF") && currentTab === 0 || game.uuid.includes("JKP") && currentTab === 1 || game.uuid.includes("ZOOM") && currentTab === 2){
                            return (
                                <Grid item xs={8} align="center">
                                    <GameTable data={game} socket={socket}/>
                                </Grid>
                            );
                        }
                    })}
            </Grid>
        </Box>
    )
}

export default AdminTable;
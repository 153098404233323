import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { ButtonBase } from '@material-ui/core';
import { useNavigate } from "react-router-dom";

const NavBar = () => {
    const navigate = useNavigate();
    return (
        <AppBar position="static" sx={{background: "#990001", m:0}}>
            <Toolbar>
                <ButtonBase onClick={() => navigate("/")}>
                    <Typography variant="h4" component="div" align="left" color="ffcc01" sx={{ flexGrow: 1, color: "#ffcc00"}}>
                        USC
                    </Typography>
                </ButtonBase>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
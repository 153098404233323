import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, Grid, Avatar, Stack, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { teal, grey } from "@mui/material/colors";
import NavBar from "../../Common/navbar";
import { useNavigate } from "react-router-dom";
import { bgcolor, style } from "@mui/system";
import RouteUrls from "../../Routes/route-urls";
import APP_BASE_URL from "../../base_url";
import axios from "axios";

const JoinGame = () => {
   const navigate = useNavigate();
   const [selected, setSelected] = React.useState("");
   const[selectedRoom, setSelectedRoom] = React.useState("");
   const [options, setOptions] = React.useState([]);

   const JFF = ["JFF-A", "JFF-B", "JFF-C", "JFF-D", "JFF-E", "JFF-F", "JFF-G", "JFF-J", "JFF-K", "JFF-L", "JFF-M", "JFF-N"];
   const JKP = ["JKP-A", "JKP-B", "JKP-C", "JKP-D", "JKP-E", "JKP-F", "JKP-G", "JKP-J", "JKP-K", "JKP-201A", "JKP-201C", "JKP-201D", "JKP-201E", "JKP-201F", "JKP-201G"];
   const ZOOM = ["ZOOM-1", "ZOOM-2", "ZOOM-3", "ZOOM-4", "ZOOM-5", "ZOOM-6", "ZOOM-7", "ZOOM-8", "ZOOM-9", "ZOOM-10", "ZOOM-11", "ZOOM-12", "ZOOM-13", "ZOOM-14", "ZOOM-15", "ZOOM-16", "ZOOM-17", "ZOOM-18", "ZOOM-19", "ZOOM-20"];

   const handleNameChange = (name) => {
      localStorage.setItem("username", name.target.value);
   };
   const handleRoomSpecificChange = (room) => {
      const selectedRoom = room.target.value;
      setSelectedRoom(selectedRoom);
      localStorage.setItem("game_id", selectedRoom);
   }
   const handleGameIdChange = (gameId) => {
      const selected = gameId.target.value;
      setSelected(selected);
      if (selected === "JKP") {
         setOptions(JKP);
      } else if (selected === "JFF") {
         setOptions(JFF);
      } else if (selected === "ZOOM") {
         setOptions(ZOOM);
      }else{
         setOptions([]);
      }
      setSelectedRoom("select Room");

   };
   const handlSubmit = () => {
      var gameId = document.getElementById("roomID").value;
      setSelectedRoom(gameId);
      localStorage.setItem("game_id", gameId);
      axios.get(APP_BASE_URL + "/gameIDCheck?gameId=" + gameId).then((response) => {
         if (response.data == "success") {
            navigate("../" + RouteUrls.MAINGAME);
         } else {
            alert(response.data);
         }
      });
   };
   const handleKeyDown = (event) => {
      if (event.key === "Enter") {
         handlSubmit();
      }
   };
   return (
      <Box sx={{ p: 0 }}>
         <NavBar />
         <Box sx={{ mt: 10 }}>
            <Grid container spacing={5} justifyContent="center">
               <Grid item xs={8} align="center">
                  <Typography variant="h5" component="h5" align="center" gutterBottom>
                     Please Enter Your Name:
                  </Typography>
                  <TextField
                     id="name"
                     label="Type here..."
                     variant="standard"
                     onChange={handleNameChange}
                  />
               </Grid>
               <Grid item xs={8} align="center">
                  <Typography variant="h5" component="h5" align="center" gutterBottom>
                     Please Select Game Location:
                  </Typography>
                  <select style={{width:200, margin:16, height: 40}} id="gameID" label= "Game" onChange={handleGameIdChange}>
                    <option value="selectGame" selected disabled>Select Game Location</option>
                    <option value="JKP">JKP</option>
                    <option value="JFF">JFF</option>
                    <option value="ZOOM">ZOOM</option>
                  </select>
                  <div>
                    <select style={{width:200, margin:16, height: 40}} id="roomID" label= "Room" onChange={handleRoomSpecificChange} value={selectedRoom}>
                     <option value="selectRoom" selected disabled>Select Room</option>
                     {options.map((option) => (
                           <option key={option}>{option}</option>
                     ))}
                    </select>
                  </div>
               </Grid>
               <Grid item xs={8} align="center">
                  <Button onClick={handlSubmit}>Confirm</Button>
               </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <Button sx={{ width: '100px', height: '25px' }} variant="contained" onClick={() => navigate('/')}>Go Back</Button>
            </Box>
         </Box>
      </Box>
   );
};

export default JoinGame;

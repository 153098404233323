import { createTheme } from '@mui/material/styles';
import { teal, grey, red, cyan } from '@mui/material/colors';

const theme = createTheme({
    components: {
        MuiButton: {
            defaultProps: {
                backgroundColor: cyan,
                variant: "outlined",
                sx: {
                    width: 300,
                    height: 50,
                }
            },
        },
    },
});

export default theme;
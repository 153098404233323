import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import {
  Button,
  Grid,
  Avatar,
  Stack,
  IconButton,
  ListItemButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import { teal, grey, red, orange, cyan } from "@mui/material/colors";
import NavBar from "../../Common/navbar";
import { useNavigate } from "react-router-dom";
import { bgcolor, style } from "@mui/system";
import RouteUrls from "../../Routes/route-urls";
import APP_BASE_URL from "../../base_url";
import GameCard from "./gameCard";
import Board from "./boardconsultant";
import BoardAcademic from "./boardacademic";
import BoardConsultant from "./boardconsultant";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {
  ListItemAvatar,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

const drawerWidth = 400;

const teams = [
  {
    name: "Orange",
    bgcolor: orange,
    color: grey[50],
  },
  {
    name: "Blue",
    bgcolor: cyan,
    color: grey[50],
  },
  {
    name: "Waiting",
    bgcolor: grey,
    color: grey[50],
  },
];

const team_color2id = {
  orange: 0,
  blue: 1,
};

const MainGame = () => {
  const [socket, setSocket] = useState(null);
  const [my_team_id, setTeamId] = useState(2);
  const [all_players, setAllPlayers] = useState(new Array(20).fill([]));
  const [my_cards, setMyCards] = useState([]);
  const [my_chips, setMyChips] = useState(0);
  const [is_my_turn, setIsMyTurn] = useState(false);
  const [paused_game, setPausedGame] = useState(false);
  const [can_detour, setCanDetour] = useState(false);
  const [showingDraw, setShowingDraw] = useState(false);
  const [is3d, setIs3d] = useState(false);
  const [version, setVersion] = useState("");
  const [areButtonsDisabled, setButtonsDisabled] = useState(false);
  const [audioTimeMilli, setAudioTimeMilli] = useState(25000);
  const latestCardRef = useRef(null);
  const username = localStorage.getItem("username");
  const is_admin = username === "admin123";
  const my_initials = username
    .split(" ")
    .map((s) => s[0])
    .join("");
  const my_avatar = (
    <Avatar
      sx={{
        bgcolor: teams[my_team_id].bgcolor[500],
        color: teams[my_team_id].color,
      }}
      children={my_initials}
    />
  );
  const navigate = useNavigate();
  const player2avatar = (p) => {
    const team_id = team_color2id[p.team];
    const initials = p.name
      .split(" ")
      .map((s) => s[0])
      .join("");
    return (
      <Tooltip title={p.name}>
        <Avatar
          sx={{
            bgcolor: teams[team_id].bgcolor[500],
            color: teams[team_id].color,
          }}
          children={initials}
        />
      </Tooltip>
    );
  };

  const start = () => {
    socket.emit("startGame");
  };
  const next = () => {
    setShowingDraw(true);
  };
  const executeNext = () => {
    setShowingDraw(false);
    socket.emit("nextTurn");
    setIsMyTurn(false);
    setCanDetour(false);
  };
  const detour = () => {
    socket.emit("detour");
    setIsMyTurn(false);
    setCanDetour(false);
  };

  useEffect(() => {
    if (username === null) {
      navigate("../");
    } else {
      const newSocket = io(APP_BASE_URL);
      setSocket(newSocket);
      return () => newSocket.close();
    }
  }, []);

  const handle_all_players = (players) => {
    var out = new Array(20);
    for (var i = 0; i < out.length; i++) {
      out[i] = new Array(0);
    }
    players.forEach((x, i) => {
      if (x.name === username) {
        setMyCards(x.cards.reverse());
        setMyChips(x.chips);
      }
      out[x.boardPosition].push(x);
    });
    setAllPlayers(out);
  };

  const cards_info_handle = (cards) => {
    setMyCards(cards.reverse());
  };

  const handle_detour_signal = () => {
    setTimeout(() => {
      setCanDetour(true);
    }, audioTimeMilli);
  };

   const reloadPage = () => {
      // window.location.reload();
      window.location.href = window.location.href;
      // history.go(0);
    };

  const handle_my_turn_signal = (data) => {
    console.log("HANDLE MY TURN SIGNAL:", data);
     // MOVE BACK AFTER setIsMyTurn!!!!
     setTimeout(() => {
       setIsMyTurn(true);
       reloadPage();
    }, data);
  };

  const handle_paused_game = () => {
    setPausedGame(true);
  };

  const handle_continue_game = () => {
    setPausedGame(false);
  };

  const prevCardsCount = useRef(my_cards.length);
  useEffect(() => {
    const prevCount = prevCardsCount.current;
    if (my_cards.length > prevCount) {
      var card = 0;
      while(my_cards[card].specialCard == 1) {
        console.log("special card: " + my_cards[card].specialCard);
        card += 1;
      }
      latestCardRef.current = my_cards[card];
      console.log("chosen non-special card:" + latestCardRef.current.specialCard);

      const audioTimeout = latestCardRef.current.audioDuration
      const newCardAudio = latestCardRef.current.audioMatch
      if (is_admin && newCardAudio) {
        let audio = new Audio(`/chipAudio/${newCardAudio}`);
        audio.play();
      }
      const duration = audioTimeout * 1000;
      setAudioTimeMilli(duration)
    }
    prevCardsCount.current = my_cards.length;
  }, [my_cards]);

  useEffect(() => {
    const initInfoHandle = (
      color,
      players,
      version,
      cards,
      my_turn,
      potential_detour
    ) => {
      if (color === "blue") {
        setTeamId(1);
      } else {
        setTeamId(0);
      }
      handle_all_players(players);
      cards_info_handle(cards);
      setVersion(version);
      setIsMyTurn(my_turn);
      setCanDetour(potential_detour);
      console.log(version);
    };
    if (socket !== null) {
      const join_data = {
        username: localStorage.getItem("username"),
        game_id: localStorage.getItem("game_id"),
        version: localStorage.getItem("version"),
      };
      socket.emit("user_join", join_data, initInfoHandle);
      if (join_data.username === "admin123") {
        socket.on("all_cards", cards_info_handle);
      }
      socket.on("skip_turn", )
      socket.on("all_players", handle_all_players);
      socket.on("your_turn", handle_my_turn_signal);
      socket.on("potential_detour", handle_detour_signal);
      socket.on("game_pause", handle_paused_game);
      socket.on("game_continue", handle_continue_game);
    }
  }, [socket]);

  const renderChipStack = (numChips, CHIP_COLORS, CHIP_HEIGHT, CHIP_WIDTH) => {
    const canvas = document.createElement("canvas");
    const stripeDivision = 9;
    const shadow = 0.44;
    const gradientWidth = Math.round(CHIP_WIDTH / 3);
    canvas.width = CHIP_WIDTH;
    canvas.height = CHIP_HEIGHT * numChips;
    const context = canvas.getContext("2d");
    context.fillStyle = CHIP_COLORS[0];
    context.fillRect(0, 0, CHIP_WIDTH, CHIP_HEIGHT * numChips);
    context.fillStyle = CHIP_COLORS[1];
    for (let i = 0; i < numChips; i++) {
      const start =
        Math.round(Math.random() * (CHIP_WIDTH / stripeDivision) * 2) -
        CHIP_WIDTH / stripeDivision;
      for (
        let x = start;
        x < CHIP_WIDTH + CHIP_WIDTH / stripeDivision;
        x += (CHIP_WIDTH / stripeDivision) * 3
      ) {
        let width = Math.min(CHIP_WIDTH / stripeDivision, 10000);
        context.fillRect(x, CHIP_HEIGHT * i, width, CHIP_HEIGHT);
      }
    }
    const grd = context.createLinearGradient(0, 0, gradientWidth, 0);
    grd.addColorStop(0, "rgba(0,0,0," + shadow.toString() + ")");
    grd.addColorStop(1, "rgba(0,0,0,0)");
    context.fillStyle = grd;
    context.fillRect(0, 0, gradientWidth, CHIP_HEIGHT * numChips);
    const grd2 = context.createLinearGradient(
      CHIP_WIDTH - gradientWidth,
      0,
      CHIP_WIDTH,
      0
    );
    grd2.addColorStop(0, "rgba(0,0,0,0)");
    grd2.addColorStop(1, "rgba(0,0,0," + shadow.toString() + ")");
    context.fillStyle = grd2;
    context.fillRect(
      CHIP_WIDTH - gradientWidth,
      0,
      gradientWidth,
      CHIP_HEIGHT * numChips
    );
    canvas.style.flexGrow = 0;
    canvas.style.width = canvas.width.toString() + "px";
    canvas.style.height = canvas.height.toString() + "px";
    canvas.style.marginRight = (CHIP_HEIGHT * 2).toString() + "px";
    canvas.style.overflow = "hidden";
    canvas.style.borderRadius = Math.floor(CHIP_HEIGHT / 2).toString() + "px";
    return canvas;
  };

  const genChips = (
    numChips,
    CHIP_COLORS = ["red", "white"],
    CHIP_HEIGHT = 4,
    CHIP_WIDTH = 36,
    maxStackHeight = 72
  ) => {
    const container = document.createElement("div");
    container.style.display = "flex";
    container.style.alignItems = "flex-end";
    while (numChips > 0) {
      const amt = Math.min(Math.floor(maxStackHeight / CHIP_HEIGHT), numChips);
      container.appendChild(
        renderChipStack(amt, CHIP_COLORS, CHIP_HEIGHT, CHIP_WIDTH)
      );
      numChips -= amt;
      // console.log(numChips);
    }
    container.style.minHeight = maxStackHeight.toString() + "px";
    return container;
  };

  const CHIP_TARGET_ID = "__chip-target";
  const chipEl = genChips(
    my_chips,
    [teams[my_team_id].bgcolor[500], "white"],
    3,
    24,
    36
  );

  useEffect(() => {
    document.getElementById(CHIP_TARGET_ID).innerHTML = "";
    document.getElementById(CHIP_TARGET_ID).appendChild(chipEl);
  }, [my_chips]);

  const listButtonStyle = {
    textAlign: "center",
    borderRadius: 4,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    margin: 16,
    padding: 8,
  };

  const detourButtonStyle = {
    textAlign: "center",
    borderRadius: 4,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    margin: 16,
    padding: 8,
    backgroundColor: "turquoise",
  };

  const activeListButtonStyle = {
    backgroundColor: teams[my_team_id].bgcolor[500],
    color: "white",
    fontWeight: "bold",
    borderRadius: 4,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    margin: 16,
    padding: 8,
  };

  return (
    <div>
      <Box sx={{ p: 0 }}>
        <Drawer
          anchor="left"
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Divider />
          <List disablePadding>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    ml: 3,
                    mr: 3,
                    bgcolor: teams[my_team_id].bgcolor[500],
                    color: teams[my_team_id].color,
                  }}
                  children={my_initials}
                />
              </ListItemAvatar>
              <ListItemText
                primary={username}
                secondary={"💰 Chips: " + my_chips}
              />
              <ListItemText id={CHIP_TARGET_ID} />
            </ListItem>
            <Divider />
            {is_admin && (
              <>
                <ListItem>
                  {" "}
                  Game Room Code: {localStorage.getItem("game_id")}
                </ListItem>
                <Divider />
              </>
            )}
            {!showingDraw && (
              <>
                <ListItem>
                  {is_admin && (
                    <ListItemButton sx={{ border: 1 }} onClick={() => navigate("../" +RouteUrls.ADMINVIEW)}>
                      Go to Admin Page
                    </ListItemButton>
                  )}
                  {!is_admin && (
                    <ListItemButton
                      style={
                        !is_my_turn || showingDraw
                          ? listButtonStyle
                          : activeListButtonStyle
                      }
                      onClick={next}
                      disabled={!is_my_turn || showingDraw || paused_game || areButtonsDisabled}
                    >
                      Next
                    </ListItemButton>
                  )}
                  {!is_admin && (
                    <ListItemButton
                      style={!is_my_turn || showingDraw || !can_detour
                        ? listButtonStyle
                        : detourButtonStyle}
                      onClick={detour}
                      disabled={!can_detour || paused_game || areButtonsDisabled}
                    >
                      Detour
                    </ListItemButton>
                  )}
                </ListItem>
                <Divider />
              </>
            )}
            {showingDraw ? (
              <>
                <ListItemText
                  primary={"Draw a card: "}
                  style={{ marginTop: 16, marginLeft: 32, marginBottom: -8 }}
                />
                <ListItem key={"_cards"} disablePadding onClick={executeNext}>
                  {[0, 1, 2].map((t) => {
                    return (
                      <GameCard
                        small
                        key={t}
                        color={teams[my_team_id].bgcolor}
                      />
                    );
                  })}
                </ListItem>
                <Divider />
              </>
            ) : null}
            <TransitionGroup>
              {my_cards.map((item) => {
                return (
                  <Collapse key={item.text}>
                    <ListItem key={item.text} disablePadding>
                      <GameCard
                        color={teams[team_color2id[item.team]].bgcolor}
                        text={item.text}
                        effect={item.effect}
                        audioMatch={item.audioMatch}
                        audioDuration={item.audioDuration}
                      />
                    </ListItem>
                  </Collapse>
                );
              })}
            </TransitionGroup>
            <ListItem
              /*onClick={() => setIs3d(!is3d)}*/ style={{
                paddingRight: 32,
                marginLeft: 16,
                marginBottom: 8,
              }}
            >
              <ListItemText primary={"3D View"} />
              <Switch checked={is3d} onChange={() => setIs3d(!is3d)} />
            </ListItem>
          </List>
        </Drawer>
      </Box>
      {version == "consultant" && (
        <BoardConsultant
          is3d={is3d}
          academic={false}
          avatar={all_players.map((player_list) => {
            return player_list.map(player2avatar);
          })}
        ></BoardConsultant>
      )}
      {version == "academic" && (
        <BoardAcademic
          is3d={is3d}
          academic={true}
          avatar={all_players.map((player_list) => {
            return player_list.map(player2avatar);
          })}
        ></BoardAcademic>
      )}
    </div>
  );
};

export default MainGame;

import React, {useState, useEffect, useRef} from 'react';
import io from 'socket.io-client'
import Tooltip from "@material-ui/core/Tooltip";
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { Button, Grid, Select,FormControl, Avatar, Stack, IconButton, ListItemButton, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { teal, grey, red, cyan } from '@mui/material/colors';
import NavBar from '../../Common/navbar';
import { useNavigate } from "react-router-dom";
import { bgcolor, style } from '@mui/system';
import RouteUrls from "../../Routes/route-urls";
import APP_BASE_URL from '../../base_url'
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import { ListItemAvatar, List, ListItem, ListItemText, MenuItem } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {emptyRoom} from '../NewGame'
import LinearProgress from '@mui/material/LinearProgress';

const SummaryLine = (props) => {
   const players = props.data.player_array;
   const uuid = props.data.uuid;

   return (
      <Grid container alignItems="center" sx={{ mb: 8 }}>
         <Grid item xs={2}>
            <Typography variant="body2">Game Id: {uuid}</Typography>
         </Grid>
         <Grid item xs={10}>
            <LinearProgress variant="determinate" value={0} />
            <Box position="relative" display="flex">
               <Box position="absolute" left="0%">
                  <Typography variant="body2" sx={{ marginTop: '10px' }}>AA</Typography>
                  <Box position="absolute" top="0px" left="50%" transform="translateX(-50%)" width="2px" height="10px" backgroundColor="black"></Box>
               </Box>
               <Box position="absolute" left="19%">
                  <Typography variant="body2" sx={{ marginTop: '10px' }}>C</Typography>
                  <Box position="absolute" top="0px" left="50%" transform="translateX(-50%)" width="2px" height="10px" backgroundColor="black"></Box>
               </Box>
               <Box position="absolute" left="47%">
                  <Typography variant="body2" sx={{ marginTop: '10px' }}>PL</Typography>
                  <Box position="absolute" top="0px" left="50%" transform="translateX(-50%)" width="2px" height="10px" backgroundColor="black"></Box>
               </Box>
               <Box position="absolute" left="76%">
                  <Typography variant="body2" sx={{ marginTop: '10px' }}>P</Typography>
                  <Box position="absolute" top="0px" left="50%" transform="translateX(-50%)" width="2px" height="10px" backgroundColor="black"></Box>
               </Box>

               {players.map((player, index) => {
               const currentPosition = (player.boardPosition / 20) * 100;
               const overlappingPlayers = players.filter((p, i) => i !== index && p.boardPosition === player.boardPosition);

               let topPosition = -16 - (index * 12); 

               return (
                  <Box key={player.name} position="absolute" left={`${currentPosition}%`} top={`${topPosition}px`}>
                     <Typography variant="body2" style={{ fontWeight: "bold", width: "12px", height: "12px", borderRadius: "50%", textAlign: "center", color: "white", fontSize: "10px", backgroundColor: player.team === "blue" ? "blue" : "orange" }}>
                     {player.name.charAt(0).toUpperCase()}
                     </Typography>
                  </Box>
               );
               })}
            </Box>
         </Grid>
      </Grid>
   );
};

const ProgressLine = () => {
   const [socket, setSocket] = useState(null);
   const [gameList, setGameList] = useState([]);
   const [currentTab, setCurrentTab] = useState(-1);

   useEffect(() => {
      const newSocket = io(APP_BASE_URL);
      setSocket(newSocket);
      return () => newSocket.close();
   }, []);

   const info_handle = (info) => {
      console.log(info);
      setGameList(info);
   };

   useEffect(() => {
      if (socket !== null) {
         const join_data = {
            username: "real_admin",
         };
         socket.emit("user_join", join_data, info_handle);
         socket.on("game_info", info_handle);
      }
   }, [socket]);

   const handleRoomStuff = (gameId) => {
      const selected = gameId.target.value;
      if (selected === "JKP") {
          setCurrentTab(1);
      } else if (selected === "JFF") {
          setCurrentTab(0);
      } else if (selected === "ZOOM") {
          setCurrentTab(2);
      }
      else {
          setCurrentTab(-1);
      }
    };

   return (
      <Box sx={{ mt: 5, mb: 5 }}>
         <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h6">Filter By Building: </Typography>
            <select style={{width:200, margin:16, height: 40}} id="gameID" label= "Game" onChange={handleRoomStuff}>
               <option value="JFF" selected disabled>Choose Building</option>
               <option value="ALL">ALL</option>
               <option value="JKP">JKP</option>
               <option value="JFF">JFF</option>
               <option value="ZOOM">ZOOM</option>
            </select>
         </Grid>
         <Grid container spacing={3} justifyContent="center">
            {gameList.map((game) => {
               if(currentTab === -1 || game.uuid.includes("JFF") && currentTab === 0 || game.uuid.includes("JKP") && currentTab === 1 || game.uuid.includes("ZOOM") && currentTab === 2){                           
                  return (
                     <Grid item xs={8} align="center">
                        <SummaryLine data={game} socket={socket} />
                     </Grid>
                  );
               }
            })}
         </Grid>
      </Box>
   );
};

export default ProgressLine;
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
import Box from '@mui/material/Box';
import React, {useEffect} from 'react';

const Board3D = (props) => {
  const TARGET_ID = "__3d-target";
  useEffect(() => {
    const CAM_CONST = "camera-position";
    const DEFAULT_CAM_POSITION = {x: -12, y: 16, z: 12};
    const MIN_CAM_DISTANCE = 10;
    const MAX_CAM_DISTANCE = 100;
    const LIGHT_DISTANCE = 100;
    const N_STEPS = 13;
    const STEP_WIDTH = 8;
    const STEP_DEPTH = 1.5;
    const PLAYER_SIZE = 1;
    const PLAYER_HEIGHT = 0.5;
    const COLUMN_LEVELS = [2,4,6,8,11];
    const renderBoard = () => {
      const targetEl = document.getElementById(TARGET_ID);
      const viewerWidth = targetEl.getBoundingClientRect().width;
      const viewerHeight = window.innerHeight;
      const scene = new THREE.Scene();
  		const camera = new THREE.PerspectiveCamera(75, viewerWidth / viewerHeight, 0.1, 1000);

  		const renderer = new THREE.WebGLRenderer();
  		renderer.setSize(viewerWidth, viewerHeight);
      renderer.setClearColor(0xffffff, 0);
      renderer.shadowMapEnabled = true;
      targetEl.innerHTML = "";
  		targetEl.appendChild(renderer.domElement);

      const labelRenderer = new CSS2DRenderer();
      labelRenderer.setSize(viewerWidth, viewerHeight);
      labelRenderer.domElement.style.position = 'absolute';
      labelRenderer.domElement.style.top = '0px';
      labelRenderer.domElement.style.zIndex = '1000';
      targetEl.appendChild(labelRenderer.domElement);

      const obj = JSON.parse(localStorage.getItem(CAM_CONST) || JSON.stringify(DEFAULT_CAM_POSITION));
  		camera.position.set(obj.x, obj.y, obj.z);
      const controls = new OrbitControls(camera, labelRenderer.domElement);
      //controls.autoRotate = true;
      //controls.autoRotateSpeed = -1;
      controls.minDistance = MIN_CAM_DISTANCE;
      controls.maxDistance = MAX_CAM_DISTANCE;
      controls.maxPolarAngle = Math.PI / 2;
      controls.update();

      controls.addEventListener('change', () => {
        localStorage.setItem(CAM_CONST, JSON.stringify({
          x: camera.position.x,
          y: camera.position.y,
          z: camera.position.z,
        }))
      });

      function animate() {
      	requestAnimationFrame(animate);
      	controls.update();
      	renderer.render(scene, camera);
      	labelRenderer.render(scene, camera);
      }
      animate();

      const spotLightA = new THREE.SpotLight(0xffffff);
      spotLightA.position.set(LIGHT_DISTANCE, LIGHT_DISTANCE, LIGHT_DISTANCE * -1);
      spotLightA.castShadow = true;
      scene.add(spotLightA);

      const spotLightB = new THREE.SpotLight(0xffffff);
      spotLightB.position.set(LIGHT_DISTANCE * -1, LIGHT_DISTANCE, LIGHT_DISTANCE);
      spotLightB.castShadow = true;
      scene.add(spotLightB);

      const boxGeometry = new THREE.BoxGeometry(STEP_WIDTH, 1, STEP_DEPTH);

      
        
      for (let a = 0; a < N_STEPS; a++) {
        const labelDiv = document.createElement("div");
        labelDiv.className = "label";

        for (let b = 0; b < a + 1; b++) {
        
          let color = 'orange';
          if(props.academic){
            labelDiv.textContent = "PhD & Post Doc";
          }else{
            labelDiv.textContent = "Internship/Bachelor's";
          }
          
          

          if (1 <= a && a <= 3) {
            color = '#18A943';
            if(a==2){
              if(props.academic){
                labelDiv.textContent = "Assistant Professor";
              }else{
                labelDiv.textContent = "Associate / Analyst";
              }
            }else{
              labelDiv.textContent = "";

            }
            

          }
          else if (4 <= a && a <= 7) {
            color = '#5386E4';

            if(a==6){
              if(props.academic){
                labelDiv.textContent = "Associate Professor";
              }else{
                labelDiv.textContent = "Consultant";
              }
            }else{
              labelDiv.textContent = "";

            }

          }
          else if (8 <= a && a <= 11) {
            color = '#A487E8';
            if(a==10){
              if(props.academic){
                labelDiv.textContent = "Full Professor";
              }else{
                labelDiv.textContent = "Project Leader";
              }
            }else{
              labelDiv.textContent = "";

            }

          }
          else if (a === 12) {
            color = '#E9190F';
            if(props.academic){
              labelDiv.textContent = "Distinguished Professor";
            }else{
              labelDiv.textContent = "Partnership";
            }

          }
          

          // Create a CSS2DObject to represent the label
          const labelObject = new CSS2DObject(labelDiv);

          // Set the position of the label relative to the box
          const box = new THREE.Mesh(boxGeometry, new THREE.MeshLambertMaterial({color}));
          box.position.set(0, b - (N_STEPS / 3), ((N_STEPS / 2) - a) * STEP_DEPTH);
          box.castShadow = true;
          box.receiveShadow = true;
          box.add(labelObject);
      	  scene.add(box);
    
          if (COLUMN_LEVELS.includes(a)) {
            const widthMult = 2;
            const column = new THREE.Mesh(new THREE.BoxGeometry(STEP_DEPTH * widthMult, 1, STEP_DEPTH), new THREE.MeshLambertMaterial({ color: "#00b1b8" }));
            column.position.set(((STEP_WIDTH + (STEP_DEPTH * widthMult)) / 2), b - (N_STEPS / 3), ((N_STEPS / 2) - a) * STEP_DEPTH);
            column.castShadow = true;
            column.receiveShadow = true;
        	  scene.add(column);
          }

          
        }
      }

      

      const putPlayerChip = (level, offset, isOnColumn, color, name) => {
        const cylinderGeometry = new THREE.CylinderGeometry(PLAYER_SIZE / 2, PLAYER_SIZE / 2, PLAYER_HEIGHT, 32);
        const player = new THREE.Mesh(cylinderGeometry, new THREE.MeshLambertMaterial({color}));
        player.position.set((offset * PLAYER_SIZE * 1.5) + (isOnColumn ? ((STEP_WIDTH / 2) + PLAYER_SIZE) : 0), (level - (N_STEPS / 3)) + 0.5 + (PLAYER_HEIGHT / 2), (((N_STEPS / 2) - level)) * STEP_DEPTH);
        const playerDiv = document.createElement( 'div' );
        playerDiv.textContent = name;
        playerDiv.style.marginTop = '-24px';
        playerDiv.style.backgroundColor = 'rgba(180,180,180,0.6)';
        playerDiv.style.color = 'white';
        playerDiv.style.fontFamily = 'sans-serif';
        playerDiv.style.padding = '4px 8px';
        playerDiv.style.borderRadius = '4px';
        const playerLabel = new CSS2DObject( playerDiv );
        player.add(playerLabel);
				playerLabel.layers.set(0);
        scene.add(player);
      }

      let levelMap = {
        0: [0, false],
        1: [1, false],
        2: [2, false],
        3: [2, true],
        4: [3, false],
        5: [4, false],
        6: [4, true],
        7: [5, false],
        8: [6, false],
        9: [6, true],
        10: [7, false],
        11: [8, false],
        12: [8, true],
        13: [9, false],
        14: [10, false],
        15: [11, false],
        16: [11, true],
        17: [12, false],
      }

      console.log(props.avatar)

      for (let i in levelMap) {
        let c = 0;
        for (let child of props.avatar[i]) {
          putPlayerChip(levelMap[i][0], c, levelMap[i][1], child.props.children.props.sx.bgcolor, child.props.title);
          c++;
        }
      }

    }
    window.addEventListener('resize', renderBoard, true);
    renderBoard();
  }, [props.avatar])
  return (
    <Box id={TARGET_ID} style={{marginLeft: 399}} />
  );
}

export default Board3D;

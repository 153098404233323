import React, {useState, useEffect} from 'react';
import io from 'socket.io-client'
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button, Grid, Avatar, Stack, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { teal, grey } from '@mui/material/colors';
import NavBar from '../../Common/navbar';
import { useNavigate } from "react-router-dom";
import { bgcolor, style } from '@mui/system';
import RouteUrls from "../../Routes/route-urls";
import APP_BASE_URL from '../../base_url'
import axios from "axios"
import ProgressLine from './ProgressLine';

const ProgressView = () => {

  const [currentTab, setCurrentTab] = useState(-1);

  const titleStyle = {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  };

  const navigate = useNavigate();
        
  return (
     <Box sx={{ p: 0 }}>
        <NavBar />
        <Typography variant="h6" style={titleStyle} sx={{ mt: 8, mb: 8 }}>
          Game Progress
        </Typography>
        <ProgressLine />
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Button sx={{ width: '100px', height: '25px' }} variant="contained" onClick={() => navigate(-1)}>Go Back</Button>
        </Box>
     </Box>
  );
};

export default ProgressView;


import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { teal, grey } from '@mui/material/colors';
import GameCard from './gameCard';
import { Grid, Zoom } from '@mui/material';
import Board3D from './3dview';

const AvatarGrid = (props) => {
    require("./board.css")
    return (
        <Grid container align="center" sx={{m:3}}>
            {props.avatar.map((ava) => {
                return(
                <Zoom in={true}>
                    <Grid item xs={2}>
                        {ava}
                    </Grid>
                </Zoom>
                );
            })}
        </Grid>
    )
}

const BoardAcademic = (props) => {
    if (props.is3d) {
      return <Board3D academic={props.academic} avatar={props.avatar}/>
    }
    else {
    return(
        <Box sx={{ml:70}}>
        <table>
        <tr>
            <td class="finish">
                <p>
                    Nomination for Distinguished Professor
                    <h6>More than 29 chips: voted in as Distinguished Professor <br></br>
                        
                        Fewer than 30 chips: nomination denied--Go back 1 Space and Lose 1 Turn.</h6>
                </p>
                <AvatarGrid avatar={props.avatar[17]}></AvatarGrid>
            </td>
        </tr>
        <tr>
            <td class="purple_td">
            <h3 class="levelLabel">Full Professor</h3>
                <p class="cardText1">
                <h3>Clean Office for 1st Time in Years and Find
                Draft Article That Only Needs Copy Editing.</h3>
{/*                    <br>
                </br>
                <br>
                </br>*/}


                <em><br></br>First Player to This Space Earn 1 Credit Chip</em>
                </p>
                <AvatarGrid avatar={props.avatar[15]}></AvatarGrid>

            </td>
            <td class="sideStep">
                <p><h2>Deliver Another Conference Keynote</h2>
                <h4>Earn 3 Credit Chips</h4></p>
                <AvatarGrid avatar={props.avatar[16]}></AvatarGrid>
            </td>

        </tr>

        <tr>
            <td class="purple_td cardText1">
            <h3 class="levelLabel">Full Professor</h3>
                <AvatarGrid avatar={props.avatar[14]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="purple_td">
            <h3 class="levelLabel">Full Professor</h3>
                <p class="cardText1"><h3>Elected Chair of the Promotion and Tenure Committee</h3>
{/*                    <br><br>
*/}                    <em><br></br>If Alone on this Space, Lose 1 Turn</em>
                </p>
                <AvatarGrid avatar={props.avatar[13]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="cardText1">
            <h3 class="levelLabel">Full Professor</h3>
            <AvatarGrid avatar={props.avatar[11]}></AvatarGrid>
            </td>
            <td class="sideStep">
                <p><h2>Deliver Conference Keynote</h2>
                    <h4>Earn 3 Credit Chips</h4></p>
                    <AvatarGrid avatar={props.avatar[12]}></AvatarGrid>
            </td>
        </tr>



        <tr>
            <td class="tf">
            <h3 class="levelLabel">Associate Professor</h3>
                <p><h2>Apply to be Full Professor</h2>
{/*                    <br>
*/}                        <h4><strong>More than 15 chips: Promoted on next turn.<br></br><br></br>
{/*                        <br>
*/}                        Fewer than 16 chips: Promotion denied--Go back 1 space and lose 1 turn.</strong></h4></p>
                <AvatarGrid avatar={props.avatar[10]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="tf">
            <h3 class="levelLabel">Associate Professor</h3>
                <h3>Selected to the Committee for the
                    Department lead Search</h3><h6>3rd Player to This Space: Lose 1 Turn</h6>
                <AvatarGrid avatar={props.avatar[8]}></AvatarGrid>
            </td>
            <td class="sideStep">
                <p><h2>Co-Author a Review Article</h2>
                    <h4>Earn 3 Credit Chips</h4></p>
                    <AvatarGrid avatar={props.avatar[9]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="tf">
            <h3 class="levelLabel">Associate Professor</h3>
                <h3>Sabbatical at Last</h3>
                <h6>1st Player to This Space: Take a New Turn</h6>
                <AvatarGrid avatar={props.avatar[7]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="tf">
            <h3 class="levelLabel">Associate Professor</h3>
            <AvatarGrid avatar={props.avatar[5]}></AvatarGrid>
            </td>
            <td class="sideStep">
                <p><h2>Co-Edit a Mainstream Journal</h2>
                    <h4>Earn 3 Credit Chips</h4></p>
                    <AvatarGrid avatar={props.avatar[6]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="tff">
                <h3 class="levelLabel">Assistant Professor</h3>
                <h1>Apply for Tenure</h1>
{/*                    <br>
*/}                    <h2><strong> More than 5 chips: Tenure Approved </strong></h2>
{/*                    <br>
*/}                    <h2><strong> Less than 6 Chips Tenure Denied--Go back to PhD & Post-doc</strong></h2>
                <AvatarGrid avatar={props.avatar[4]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="tff">
                <h3 class="levelLabel">Assistant Professor</h3>
                <h1>FAST TRACK</h1>
{/*                    <br>
*/}                    <em><strong>If you have 6 Credit Chips When You Land on This Space,
                    Move Ahead 1 and Apply for Tenure.
                </strong> </em>
                <AvatarGrid avatar={props.avatar[2]}></AvatarGrid>
            </td>
            <td class="sideStep">
                <p><h2>Publish an Article 1st Tier Journal</h2>
                    <h4>Earn 3 Credit Chips</h4>
                </p>
                <AvatarGrid avatar={props.avatar[3]}></AvatarGrid>
            </td>
        </tr>
        <tr>
            <td class="tff">
                <h3 class="levelLabel">Assistant Professor</h3>
                <AvatarGrid avatar={props.avatar[1]}></AvatarGrid>
            </td>
        </tr>
        <tr>
            <td class="start">
                <Box sx= {{p:5}}>
                    <h1>PhD & Post-doc </h1>
                    <AvatarGrid avatar={props.avatar[0]}></AvatarGrid>
                </Box>
            </td>

        </tr>
    </table>
    </Box>
        );
      }
}

export default BoardAcademic;

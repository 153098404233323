import * as React from 'react';
import RouteUrls from "../../Routes/route-urls";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import NavBar from '../../Common/navbar';
import { useNavigate } from "react-router-dom";

const Lobby = () => {
    const navigate = useNavigate();
    return (
        <Box sx={{p:0}}>
            <NavBar />
            <Box sx={{mt:20}}>
                <Typography variant="h1" component="h1" align="center" gutterBottom>
                    WAGES
                </Typography>
            </Box>
            <Box sx={{mt:5}}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={8} align="center">
                        <Button variant="outlined" onClick={() => navigate(RouteUrls.NEWGAME)}>Start New Game</Button>
                    </Grid>
                    <Grid item xs={8} align="center">
                        <Button variant="outlined" onClick={() => navigate(RouteUrls.JOINGAME)}>Join Game</Button>
                    </Grid>
                    <Grid item xs={8} align="center">
                        <Button variant="outlined" onClick={() => navigate(RouteUrls.INTRO)}>Read Introduction</Button>
                    </Grid>
                    <Grid item xs={8} align="center">
                        <Button variant="outlined" onClick={() => navigate(RouteUrls.ADMINVIEW)}>Admin</Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Lobby;
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { teal, grey } from '@mui/material/colors';
import GameCard from './gameCard';
import { Grid, Zoom } from '@mui/material';
import Board3D from './3dview';

const AvatarGrid = (props) => {
    require("./board.css")
    return (
        <Grid container align="center" sx={{m:3}}>
            {props.avatar.map((ava) => {
                return(
                <Zoom in={true}>
                    <Grid item xs={2}>
                        {ava}
                    </Grid>
                </Zoom>
                );
            })}
        </Grid>
    )
}


const BoardConsultant = (props) => {
  if (props.is3d) {
    return <Board3D avatar={props.avatar}/>
  }
  else {
    return(
        <Box sx={{ml:70}}>
        <table>
        <tr>
            <td class="finish">
                <p>
                    Nomination for Partnership
                    <h6>More than 29 chips: voted in as Partner <br></br><br></br>

                    Fewer than 30 chips: lose the vote--Go back 1 Space and Lose 1 Turn.</h6>
                </p>
                <AvatarGrid avatar={props.avatar[17]}></AvatarGrid>
            </td>
        </tr>
        <tr>
            <td class="purple_td">
                <h3 class="levelLabel">Project Leader</h3>
                <p class="cardText1">
                <h1>Take a Getaway Weekend and Connect with Potential Client</h1>
                <em>First Player to this space earns 1 chip</em>
                </p>
                <AvatarGrid avatar={props.avatar[15]}></AvatarGrid>

            </td>
            <td class="sideStep">
                <p><h2>Bring 2 New Clients into the Firm</h2>
                <h4>Earn 3 Credit Chips</h4></p>
                <AvatarGrid avatar={props.avatar[16]}></AvatarGrid>
            </td>

        </tr>

        <tr>
            <td class="purple_td cardText1">
                <h3 class="levelLabel">Project Leader</h3>
                <AvatarGrid avatar={props.avatar[14]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="purple_td">
                <h3 class="levelLabel">Project Leader</h3>
                <p class="cardText1">
                    <h1>Elected Chair of the Promotion and Tenure Committee </h1>
{/*                    <br><br>
*/}                    <em>If Alone on this Space, Lose 1 Turn</em>
                </p>
                <AvatarGrid avatar={props.avatar[13]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="cardText1">
            <h3 class="levelLabel">Project Leader</h3>
            <AvatarGrid avatar={props.avatar[11]}></AvatarGrid>
            </td>
            <td class="sideStep">
                <p><h2>Bring New Client into the Firm</h2>
                    <h4>Earn 3 Credit Chips</h4></p>
                    <AvatarGrid avatar={props.avatar[12]}></AvatarGrid>
            </td>
        </tr>



        <tr>
            <td class="tf">
                <h3 class="levelLabel">Consultant</h3>
                <p><h2>Apply to be a Project Leader</h2>
                <h4>More than 15 chips: Promoted on next turn.<br></br><br></br>

                    Fewer than 16 chips: Promotion denied--Go back 1 space and lose 1 turn.
                </h4>
                </p>
                <AvatarGrid avatar={props.avatar[10]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="tf">
                <h3 class="levelLabel">Consultant</h3>
                <h3>Selected for Associate Review Committee
                    Department lead Search</h3>

                    <h5>3rd Player to this Space: Lose 1 turn</h5>
                <AvatarGrid avatar={props.avatar[8]}></AvatarGrid>
            </td>
            <td class="sideStep">
                <p><h3>Deliver Workshop on Diversity, Equity, and Inclusion</h3>
                    <h4>Earn 3 Credit Chips</h4></p>
                    <AvatarGrid avatar={props.avatar[9]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="tf">
                <h3 class="levelLabel">Consultant</h3>
                <h3>Inivited to a Client's Conference</h3>
                <h6>1st Player to this Space: Take a New Turn</h6>
                <AvatarGrid avatar={props.avatar[7]}></AvatarGrid>
            </td>
        </tr>
        <tr>
            <td class="tf">
            <h3 class="levelLabel">Consultant</h3>
            <AvatarGrid avatar={props.avatar[5]}></AvatarGrid>
            </td>
            <td class="sideStep">
                <p><h2>Make a Presentation at Executive Retreat</h2>
                    <h4>Earn 3 Credit Chips</h4></p>
                    <AvatarGrid avatar={props.avatar[6]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="tff">
                <h3 class="levelLabel">Associate / Analyst</h3>
                <h1>Apply for Promotion to Consultant</h1>
{/*                    <br>
*/}                    <h3>More than 5 chips: Promoted</h3>
{/*                    <br>
*/}                    <h3>Less than 6 chips: Promotion denied--Go back to Internship/Bachelor's space </h3>
                <AvatarGrid avatar={props.avatar[4]}></AvatarGrid>
            </td>

        </tr>
        <tr>
            <td class="tff">
                <h3 class="levelLabel">Associate / Analyst</h3>
                <h1>FAST TRACK</h1>
{/*                    <br>
*/}                    <em><strong>If you have 6 Credit Chips When You Land on This Space,
                    Move Ahead 1 and apply for promotion.
                </strong> </em>
                <AvatarGrid avatar={props.avatar[2]}></AvatarGrid>
            </td>
            <td class="sideStep">
                <p><h2>Deliver Internal Training On New Statistics Program</h2>
                    <h4>Earn 3 Credit Chips</h4>
                </p>
                <AvatarGrid avatar={props.avatar[3]}></AvatarGrid>
            </td>
        </tr>
        <tr>
            <td class="tff">
              <h3 class="levelLabel">Associate / Analyst</h3>
                <AvatarGrid avatar={props.avatar[1]}></AvatarGrid>
            </td>
        </tr>
        <tr>
            <td class="start">
                <Box sx= {{p:5}}>
                    <h1>Internship/Bachelor's </h1>
                    <AvatarGrid avatar={props.avatar[0]}></AvatarGrid>
                </Box>
            </td>

        </tr>
    </table>
    </Box>
        );
      }
}

export default BoardConsultant;

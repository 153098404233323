import React, { useState } from "react";
import { Card, CardMedia, CardContent } from "@mui/material";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, Grid, Avatar, Stack, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../Common/navbar";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { style } from "@mui/system";
import ButtonBase from "@material-ui/core/ButtonBase";
import RouteUrls from "../../Routes/route-urls";

const rooms = {
  "JFF-A": { id: 1, used: false, zoom: 1 },
  "JFF-B": { id: 2, used: false, zoom: 2 },
  "JFF-C": { id: 3, used: false, zoom: 3 },
  "JFF-D": { id: 4, used: false, zoom: 4 },
  "JFF-E": { id: 5, used: false, zoom: 5 },
  "JFF-F": { id: 6, used: false, zoom: 6 },
  "JFF-G": { id: 7, used: false, zoom: 7 },
  "JFF-J": { id: 8, used: false, zoom: 8 },
  "JFF-K": { id: 9, used: false, zoom: 9 },
  "JFF-L": { id: 10, used: false, zoom: 10 },
  "JFF-M": { id: 11, used: false, zoom: 11 },
  "JFF-N": { id: 12, used: false, zoom: 12 },

  "JKP-A": { id: 1, used: false, zoom: 1 },
  "JKP-B": { id: 2, used: false, zoom: 2 },
  "JKP-C": { id: 3, used: false, zoom: 3 },
  "JKP-D": { id: 4, used: false, zoom: 4 },
  "JKP-E": { id: 5, used: false, zoom: 5 },
  "JKP-F": { id: 6, used: false, zoom: 6 },
  "JKP-G": { id: 7, used: false, zoom: 7 },
  "JKP-J": { id: 8, used: false, zoom: 8 },
  "JKP-K": { id: 9, used: false, zoom: 9 },
  "JKP-201A": { id: 10, used: false, zoom: 10 },
  "JKP-201C": { id: 11, used: false, zoom: 11 },
  "JKP-201D": { id: 12, used: false, zoom: 12 },
  "JKP-201E": { id: 13, used: false, zoom: 13 },
  "JKP-201F": { id: 14, used: false, zoom: 14 },
  "JKP-201G": { id: 15, used: false, zoom: 15 },

  "ZOOM-1": { id: 1, used: false, zoom: 1 },
  "ZOOM-2": { id: 2, used: false, zoom: 2 },
  "ZOOM-3": { id: 3, used: false, zoom: 3 },
  "ZOOM-4": { id: 4, used: false, zoom: 4 },
  "ZOOM-5": { id: 5, used: false, zoom: 5 },
  "ZOOM-6": { id: 6, used: false, zoom: 6 },
  "ZOOM-7": { id: 7, used: false, zoom: 7 },
  "ZOOM-8": { id: 8, used: false, zoom: 8 },
  "ZOOM-9": { id: 9, used: false, zoom: 9 },
  "ZOOM-10": { id: 10, used: false, zoom: 10 },
  "ZOOM-11": { id: 11, used: false, zoom: 11 },
  "ZOOM-12": { id: 12, used: false, zoom: 12 },
  "ZOOM-13": { id: 13, used: false, zoom: 13 },
  "ZOOM-14": { id: 14, used: false, zoom: 14 },
  "ZOOM-15": { id: 15, used: false, zoom: 15 },
  "ZOOM-16": { id: 16, used: false, zoom: 16 },
  "ZOOM-17": { id: 17, used: false, zoom: 17 },
  "ZOOM-18": { id: 18, used: false, zoom: 18 },
  "ZOOM-19": { id: 19, used: false, zoom: 19 },
  "ZOOM-20": { id: 20, used: false, zoom: 20 }
};

const findAvailableRoom = () => {
  return Object.keys(rooms).filter((room) => !rooms[room].used);
};

export const emptyRoom = (gameId) => {
  rooms[gameId].used = false;
};

const NewGame = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [JFF, setJFF] = React.useState([]);
  const [JKP, setJKP] = React.useState([]);
  const [ZOOM, setZOOM] = React.useState([]);

  const all_JFF = ["JFF-A", "JFF-B", "JFF-C", "JFF-D", "JFF-E", "JFF-F", "JFF-G", "JFF-J", "JFF-K", "JFF-L", "JFF-M", "JFF-N"];
  const all_JKP = ["JKP-A", "JKP-B", "JKP-C", "JKP-D", "JKP-E", "JKP-F", "JKP-G", "JKP-J", "JKP-K", "JKP-201A", "JKP-201C", "JKP-201D", "JKP-201E", "JKP-201F", "JKP-201G"];
  const all_ZOOM = ["ZOOM-1", "ZOOM-2", "ZOOM-3", "ZOOM-4", "ZOOM-5", "ZOOM-6", "ZOOM-7", "ZOOM-8", "ZOOM-9", "ZOOM-10", "ZOOM-11", "ZOOM-12", "ZOOM-13", "ZOOM-14", "ZOOM-15", "ZOOM-16", "ZOOM-17", "ZOOM-18", "ZOOM-19", "ZOOM-20"];

  const card_style = { maxWidth: 345, m: 1 };

  React.useEffect(() => {
    const availableRooms = findAvailableRoom();
    const availset = new Set(availableRooms);
    var tempJFF = [];
    for(const room in all_JFF){
      if (availset.has(all_JFF[room])){
        tempJFF.push(all_JFF[room]);
        JFF.push(all_JFF[room]);
      }
    }
    var tempJKP = [];
    for(const room in all_JKP){
      if (availset.has(all_JKP[room])){
        tempJKP.push(all_JKP[room]);
        JKP.push(all_JKP[room]);
      }
    }
    var tempZOOM = [];
    for(const room in all_ZOOM){
      if (availset.has(all_ZOOM[room])){
        tempZOOM.push(all_ZOOM[room]);
        ZOOM.push(all_ZOOM[room]);
      }
    }
  }, []);

  const updateRoomList = () => {
    if (rooms[selected] && !rooms[selected].used) {
      setSelected(selected);
      localStorage.setItem("game_id", selected);
  
      // Mark the selected room as used
      rooms[selected].used = true;
  
      // Update the dropdown options to exclude the used room
      setOptions((prevOptions) =>
      prevOptions.filter((option) => option !== selected)
      );
    } else {
      // Handle the case where the room has already been used
      alert("This room has already been selected. Please choose another room.");
    }
  };

  const handlSubmitConsultant = () => {
    if(selected === ""){
      alert("Please select a room to continue.");
    }else{
      localStorage.setItem("username", "admin123");
      localStorage.setItem("version", "consultant");
      localStorage.setItem("gamesended",false)

      updateRoomList();
      navigate("../" + RouteUrls.MAINGAME);
    }
  };
  const handlSubmitAcademic = () => {
    if(selected===""){
      alert("Please select a room to continue.");
    }else{
      localStorage.setItem("username", "admin123");
      localStorage.setItem("version", "academic");
      localStorage.setItem("gamesended",false)

      updateRoomList();
      navigate("../" + RouteUrls.MAINGAME);
    }
    
  };
  const handleGameIdChange = (gameId) => {
    const selected = gameId.target.value;
    if (selected === "JKP") {
       setOptions(JKP);
    } else if (selected === "JFF") {
       setOptions(JFF);
    } else if (selected === "ZOOM") {
       setOptions(ZOOM);
    }else{
       setOptions([]);
    }
 };
  const handlSubmitGameRoom = (event) => {
    const selectedGameId = event.target.value;
    setSelected(selectedGameId);
    localStorage.setItem("game_id", selectedGameId);
    // Check if the selected room has already been used
  };
  return (
    <Box sx={{ p: 0 }}>
      <NavBar />
      <div>
      <Grid container spacing={5} justifyContent="center" sx={{ mt: 10 }}>
        <select style={{width:200, margin:16, height: 40}} id="gameID" label= "Game" onChange={handleGameIdChange}>
          <option value="selectGame" selected disabled>Select Game Location</option>
          <option value="JKP">JKP</option>
          <option value="JFF">JFF</option>
          <option value="ZOOM">ZOOM</option>
        </select>
        <div>
          <select style={{width:200, margin:16, height: 40}} id="roomID" label= "Room" onChange={handlSubmitGameRoom}>
            <option value="selectRoom" selected disabled>Select Room</option>
            {options.map((option) => (
                  <option key={option}>{option}</option>
            ))}
          </select>
        </div>
      </Grid>
      </div>
      <Box sx={{ mt: 5 }}>
        <Grid container spacing={5} justifyContent="center">
          <ButtonBase onClick={handlSubmitConsultant}>
            <Card sx={card_style}>
              <CardMedia
                component="img"
                height="350"
                image="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-1.2.1"
                alt="Consultant"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Consultant
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  In the consultant version of WAGES, you will advance along a
                  simple game board from Intership to Partner in a consulting
                  firm.
                </Typography>
              </CardContent>
            </Card>
          </ButtonBase>
          <ButtonBase onClick={handlSubmitAcademic}>
            <Card sx={card_style}>
              <CardMedia
                component="img"
                height="350"
                image="https://dailytrojan.com/wp-content/uploads/2021/03/stock_charlesmccollum-00389-scaled.jpg"
                alt="Academic"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Academic
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  In the academic version of WAGES, you will advance along a
                  simple game board from Post Doc to full Professor in a
                  academic institution.
                </Typography>
              </CardContent>
            </Card>
          </ButtonBase>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 2 }}>
          <Button sx={{ width: '100px', height: '25px' }} variant="contained" onClick={() => navigate('/')}>Go Back</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NewGame;

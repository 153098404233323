import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteUrls from "./Routes/route-urls";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Common/Theme";
import Lobby from "./Pages/Lobby"
import Intro from "./Pages/Introduction"
import JoinGame from "./Pages/JoinGame"
import NewGame from "./Pages/NewGame";
import MainGame from "./Pages/MainGame";
import AdminView from "./Pages/AdminView";
import Summary from "./Pages/Summary";


function App() {
    return (

        <div className="App">
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path={RouteUrls.INDEX} element={<Lobby />}/>
                    </Routes>
                    <Routes>
                        <Route path={RouteUrls.INTRO} element={<Intro />}/>
                    </Routes>
                    <Routes>
                        <Route path={RouteUrls.JOINGAME} element={<JoinGame />}/>
                    </Routes>
                    <Routes>
                        <Route path={RouteUrls.NEWGAME} element={<NewGame />}/>
                    </Routes>
                    <Routes>
                        <Route path={RouteUrls.MAINGAME} element={<MainGame />}/>
                    </Routes>
                    <Routes>
                        <Route path={RouteUrls.ADMINVIEW} element={<AdminView />}/>
                    </Routes>
                    <Routes>
                        <Route path={RouteUrls.SUMMARY} element={<Summary />}/>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;

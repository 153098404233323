import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const GameCard = (props) => {
  return (
    <a
        style={{
        display: "block",
        marginBottom: props.small ? 16 : 0,
      }}
    >
      <Card
        sx={{
          cursor: props.small ? "pointer" : "default",
          minWidth: props.small ? 92 : 275,
          minHeight: props.small ? 132 : 0,
          mt: 2,
          mr: 2,
          ml: 2,
          bgcolor: props.color[50],
        }}
      >
        <CardContent>
          <Typography sx={{ fontWeight: "bold", fontSize: 17 }} gutterBottom>
            {props.text}
          </Typography>
          <Typography sx={{ fontStyle: "italic", fontSize: 17 }} gutterBottom>
            {props.effect}
          </Typography>
        </CardContent>
      </Card>
    </a>
  );
};

export default GameCard;
